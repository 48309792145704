// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://sandbox.apiempleados.onikom.mx/api/v1/',
  API_URL_V2: 'https://sandbox.apiempleados.onikom.mx/api/v2/',
  APLICATION_BASE_URL:'http://13.235.116.151',
  APLICATION_PDF_URL:'http://13.235.116.151',
  // API_URL: 'http://192.168.1.19/soygigante-backend/public/api/v1/',
  // API_URL_V2:'http://192.168.1.19/soygigante-backend/public/api/v1/',
  // APLICATION_BASE_URL: 'http://192.168.1.19/soygigante-backend/public',
  SECRET_ENCRYPT:"YourSecretKeyForEncryption&DescryptionForGGI",
  ANALYTICS_TOKEN:"mz2OudhWSsEc8lKTYOo5ADTUIwfh9ZZifsxIN8vraIJbybhyxeFoMKW0i9VOEw6z"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
