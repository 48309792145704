import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModalService } from '../services/modal/modal.service'
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/authService.service';
import * as moment from 'moment';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private modalService: ModalService,
        private router : Router,
        private activeModal: NgbModal,
        private authService: AuthService,
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            let title = 'Ups, un error'
            let action = 'Inténtalo de nuevo'
            let error:string = err.error.exception || err.error.message || err.error.errors || err.statusText;
            console.log(error);

            switch(error){
                case 'Unknown Error':
                error = 'Error de conexión'
                break;
                case 'Internal Server Error':
                error = 'Tenemos un error interno.'
                break;
                case 'Código incorrecto':
                error = 'Inténtalo nuevamente o genera un nuevo código de verificación'
                title = 'Código de verificación incorrecto'
                action = 'Reintentar'
                break;
                case 'Inténtalo nuevamente o genera un nuevo código de verificación':
                error = 'Inténtalo nuevamente o genera un nuevo código de verificación'
                title = 'Código de verificación incorrecto'
                action = 'Reintentar'
                break;
                case 'ErrorException':
                title = 'Error al procesar solicitud'
                error = 'Inténtalo más tarde'
                action = 'Aceptar'

                  break;
                default:
                error
            }
            if (err.status == 403 || err.status == 0) {
                this.activeModal.dismissAll();
                this.authService.logout()
                if(error != 'Error de conexión')
                {
                    this.modalService.message( error , 'Vuelve a iniciar sesión', action, 'btn-danger', null, true ).subscribe(result => {
                    this.router.navigateByUrl('/login');
                    this.removeLocalStorage();
                })
                }
            } else if(err.status == 413) {
                this.modalService.message('Inténtalo nuevamente.','Los archivos deben tener un peso máximo de 25MB','Aceptar', null, "assets/images/rejected.png");
            } else {
                if (err.url.indexOf('signup') < 0){
                    this.activeModal.dismissAll();
                        this.modalService.message( error , title, action, 'btn-danger', null, true )
                }
                if (err.url.indexOf('login') > 0){
                    this.activeModal.dismissAll();
                    error = err.error.message
                    this.modalService.message(error , title, action, 'btn-danger', null, true )
                }
            }
            return throwError(error);
        }))
    }
    removeLocalStorage(){
        const date = JSON.parse(localStorage.getItem('user')).birthDate
        const myDate = new Date(); // Tu fecha
   
        // Formatea la fecha como 'DD/MM/YYYY'
        const formattedDate = moment(myDate).format('MM-DD');
        const formattedBirthday = moment(date).format('MM-DD');
        if (formattedDate == formattedBirthday) {
            localStorage.removeItem('expires_in');
            localStorage.removeItem('rsInfo');
            localStorage.removeItem('toksData');
            localStorage.removeItem('user');
            localStorage.removeItem('od_url_salary');
            localStorage.removeItem('secretPassword');
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            localStorage.removeItem('badge');
            localStorage.removeItem('comodinInfo');
        }else{
             localStorage.clear();
        }
    }
}
