<div class="container-fluid error-wrapper">
  <div class="row">
    <div class="companies col-12 d-flex align-items-center pt-3">
      <img
        src="assets/images/logo-ggi@3x.png"
        alt=""
        class="company-logo logo-ggi mx-1 mx-sm-3"
      />
      <img
        src="assets/images/logo-gg@3x.png"
        alt=""
        class="company-logo logo-gg mx-1 mx-sm-3"
      />
      <img
        src="assets/images/logo-set@3x.png"
        alt=""
        class="company-logo logo-set mx-1 mx-sm-3"
      />
      <!-- <img
        src="assets/images/logo-ths@3x.png"
        alt=""
        class="company-logo logo-ths mx-1 mx-sm-3"
      /> -->
    </div>
    <div class="companies col-12 d-flex align-items-center pt-3">
      <img
        src="assets/images/logo-grg@3x.png"
        alt=""
        class="logo-company logo-grg"
      />
      <img
        src="assets/images/logo_toks.png"
        alt=""
        class="logo-company logo-toks" style=" width: 56px; height: 48px;"
      />
      <img
        src="assets/images/logo-panda-express@3x.png"
        alt=""
        class="logo-company logo-panda mx-1"
      />
      <img
        src="assets/images/logo-beer-factory@3x.png"
        alt=""
        class="logo-company logo-beer mx-3"
      />
    </div>
    <div class="companies col-12 d-flex align-items-center pt-3">
    <img
        src="assets/images/logo-shake-shack@3x.png"
        alt=""
        class="logo-company logo-30 mx-3"
      />
      <img
        src="assets/images/logo-farolito@3x.png"
        alt=""
        class="logo-company logo-30"
      />
      <img
      src="assets/images/logo-fesa@3x.png"
      alt=""
      class="company-logo logo-fesa3 mx-1 mx-sm-3"
      />
      <img
      src="assets/images/logo-marchand@3x.png"
      alt=""
      class="company-logo logo-marchand mx-1 mx-sm-3"
      />
    </div>
    <!-- LOGOS FALTANTES -->
    <div class="companies col-12 d-flex align-items-center pt-3" style="height: 76px;">
      <img
      src="assets/images/logo-OD.png"
      alt=""
      class="company-logo logo-ofd mx-1 mx-sm-3"
      />
      <img
      src="assets/images/logo-rs.png"
      alt=""
      class="company-logo logo-rsh mx-1 mx-sm-3"
      />
      <img
      src="assets/images/logo_gs.png"
      alt=""
      class="company-logo logo-sqr mx-1 mx-sm-3"
      />
      <img
      src="assets/images/logo_ci.png"
      alt="50"
      class="logo-company logo-ci"
      />
    </div>
    <div class="col-12 text-center mt-4">
      <h1 class="mobile-title">¡Estamos en mantenimiento!</h1>
      <p class="mobile-content">
        Por el momento <b>Soy Gigante</b> no se
        <br />
        encuentra disponible, estamos trabajando para <br />
        brindarte una mejor experiencia.
      </p>
      <p class="mobile-content">Lamentamos los inconvenientes.</p>
      <div class="w-100">
        <img src="assets/images/not-access.png" alt="" class="error-image" />
      </div>
      <button class="btn ggi-button-red my-2" routerLink="/login">
        Reintentar
      </button>
    </div>
  </div>
</div>