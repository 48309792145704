<div class="container-fluid error-wrapper">
  <div class="row">
    <div class="companies col-12 d-flex align-items-center pt-3">
      <img
        src="assets/images/logo-ggi@3x.png"
        alt=""
        class="company-logo logo-ggi mx-1 mx-sm-3"
      />
      <img
        src="assets/images/logo-gg@3x.png"
        alt=""
        class="company-logo logo-gg mx-1 mx-sm-3"
      />
      <img
        src="assets/images/logo-set@3x.png"
        alt=""
        class="company-logo logo-set mx-1 mx-sm-3"
      />
    <!--   <img
        src="assets/images/logo-ths@3x.png"
        alt=""
        class="company-logo logo-ths mx-1 mx-sm-3"
      /> -->
      
    </div>
    <div class="companies col-12 d-flex align-items-center pt-3">
      <img
        src="assets/images/logo-grg@3x.png"
        alt=""
        class="logo-company logo-grg mx-2"
      />
      <img
        src="assets/images/logo_toks.png"
        alt=""
        class="logo-company logo-toks mx-2" style=" width: 56px; height: 48px;"
      />
      <img
        src="assets/images/logo-panda-express@3x.png"
        alt=""
        class="logo-company logo-panda mx-1"
      />
      <img
        src="assets/images/logo-beer-factory@3x.png"
        alt=""
        class="logo-company logo-beer mx-1"
      />
     </div>
      <div class="companies col-12 d-flex align-items-center pt-3">
        <img
        src="assets/images/logo-shake-shack@3x.png"
        alt=""
        class="logo-company logo-30 mx-1"
      />
      <img
        src="assets/images/logo-farolito@3x.png"
        alt=""
        class="logo-company logo-30"
      />
        <img
      src="assets/images/logo-fesa@3x.png"
      alt=""
      class="logo-company logo-fs"
      />
     <img
      src="assets/images/logo-marchand@3x.png"
      alt=""
      class="logo-company logo-marchand"
    />      
    </div>
    <div class="companies col-12 d-flex align-items-center pt-3">
      <img
      src="assets/images/logo-OD.png"
      alt=""
      class="logo-company logo-od"
    />
      <img
       src="assets/images/logo-rs.png"
       alt=""
       class="logo-company logo-rs"
     />
      <img
       src="assets/images/logo_gs.png"
       alt=""
       class="logo-company logo-gs"
      />
      <img
      src="assets/images/logo_ci.png"
      alt="50"
      class="logo-company logo-ci"
      />

    </div>
    <div class="col-12 text-center mt-3">
      <h1 class="mobile-title">Acceso bloqueado</h1>
      <p class="mobile-content">
        No tienes permiso para ingresar a esta sección, si esto
        <br />
        es algún error, por favor envía un correo a
        <br />
        <b>ayudaapp@gigante.com.mx</b>
      </p>
      <p class="mobile-content">Lamentamos los inconvenientes.</p>
      <button
        href="/dashboard"
        class="btn ggi-button-red ml-2"
        (click)="toDashboard()"
      >
        Regresar a inicio
      </button>

      <div class="w-100">
        <img src="assets/images/not-access.png" alt="" class="error-image" />
      </div>
    </div>
  </div>
</div>