import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModalComponent } from 'src/app/components/shared/modal/modal.component';



@Injectable({
  providedIn: 'root'
})

export class ModalService {

  constructor(private ngbModal: NgbModal) { }

  message(prompt: string, title = 'Message', buttonContent = 'Ok', btnError = 'bj-green', image = null, error = false): Observable<boolean> {
    const modal = this.ngbModal.open(
      ModalComponent, { backdrop: 'static', centered: true });

    modal.componentInstance.prompt = prompt;
    modal.componentInstance.title = title;
    modal.componentInstance.buttonContent = buttonContent;
    modal.componentInstance.btnError = (btnError == null) ? 'bj-green' : btnError;
    modal.componentInstance.image = image;
    modal.componentInstance.error = error;

    return from(modal.result).pipe(
      catchError(error => {
        return of(undefined);
      })
    );
  }
}
