import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Component401 } from './views/errors/401/401.component';
import { AppMobileComponent } from './views/app_mobile/app_mobile.component';
import { Maintenance } from './views/errors/401/maintenance/maintenance';
import { SolicitarComponent } from './views/services/solicitar/solicitar.component';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule) 
  },
  {
    path: 'agreements',
    loadChildren: () => import('./views/agreements/agreements.module').then(m => m.AgreementsModule) 
  },
  {
    path: 'directory',
    loadChildren: () => import('./views/directory/directory.module').then(m => m.DirectoryModule) 
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule) 
  },
  
 {
    path: 'system_design',
    loadChildren: () => import('./views/system_design/system-design.module').then(m => m.SystemDesignModule) 
  },
  {
    path: 'register',
    loadChildren: () => import('./views/register/register.module').then(m => m.RegisterModule) 
  },
  
  {
    path: 'recover_password',
    loadChildren: () => import('./views/password/recover_password/recover-password.module').then(m => m.RecoverPasswordModule) 
  },
  {
    path: 'event_detail', loadChildren: () => import('./views/event_detail/event-detail.module').then(m => m.EventDetailModule) 
  },
  { 
    path: 'news_detail', loadChildren: () => import('./views/news-detail/news-detail.module').then(m => m.NewsDetailModule) 
  },
  { path: 'user_profile', loadChildren: () => import('./views/user-profile/user-profile.module').then(m => m.UserProfileModule) },
  { 
    path: 'marketplace', loadChildren: () => import('./views/marketplace/marketplace.module').then(m => m.MarketplaceModule) 
  },
  { 
    path: 'covid', loadChildren: () => import('./views/covid/covid.module').then(m => m.CovidModule) 
  },
  { 
    path: 'banner/:title', loadChildren: () => import('./views/banner/banner.module').then(m => m.BannerModule) 
  },
  {
    path:'error/401',
    component:Component401
  },
  {
    path:'maintenance',
    component: Maintenance
  },
  {
    path:'services',
    loadChildren: () => import('./views/services/services.module').then(m => m.ServicesModule)
  },
  // {
  //   path:'solicitar',
  //   loadChildren: () => import('./views/services/solicitar/solicitar.module').then(m => m.SolicitarModule)
  // },
  {
    path:'app/download',
    component:AppMobileComponent
  },
  { path: 'news_favorite', loadChildren: () => import('./views/news-favorite/news-favorite.module').then(m => m.NewsFavoriteModule) },
  { path: 'events_attend', loadChildren: () => import('./views/events-attend/events-attend.module').then(m => m.EventsAttendModule) },
  { path: 'post_detail', loadChildren: () => import('./views/post-detail/post.module').then(m => m.PostModule) },
  { path: 'mi_gigante', loadChildren: () => import('./views/mi-gigante/mi-gigante.module').then(m => m.MiGiganteModule) },
  { path: 'herramientas', loadChildren: () => import('./views/herramientas/herramientas.module').then(m => m.HerramientasModule) },
  { path: 'documentos', loadChildren: () => import('./views/documents/documents.module').then(m => m.DocumentsModule) },
  { path: 'reconocimientos', loadChildren: () => import('./views/recognitions/recognitions.module').then(m => m.RecognitionsModule) },


  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
