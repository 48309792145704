import { Directive , Output, EventEmitter, HostListener} from '@angular/core';

@Directive({
  selector: '[appScrollEvent]'
})
export class ScrollEventDirective {
  @Output() scroll: EventEmitter<boolean> = new EventEmitter<false>();

  @HostListener("scroll", ["$event"])
  public onListenerTriggered(event: any): void {
    this.scroll.emit(true);
  }
}
