import { Component } from "@angular/core";

@Component({
    selector:'app-401',
    templateUrl:'./401.component.html'
})

export class Component401{
    toDashboard(){
        window.location.href = "/dasboard";
    }
}