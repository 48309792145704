import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwtInterceptor } from './interceptors/jwt.interceptor';

/* Loader Module */
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ModalComponent } from './components/shared/modal/modal.component';
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LightboxModule } from 'ngx-lightbox';
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { Component401 } from './views/errors/401/401.component';
import { Maintenance } from './views/errors/401/maintenance/maintenance';
import { AppMobileComponent } from './views/app_mobile/app_mobile.component';
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ChartsModule } from 'ng2-charts';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { ScrollEventDirective } from './directives/scroll-event.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';


registerLocaleData(localeEsMx, 'es-MX');


@NgModule({
  declarations: [
    AppComponent,
    Component401,
    AppMobileComponent,
    Maintenance,
    PreventDoubleClickDirective,
    ScrollEventDirective,

  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: 'rgba(0,0,0,0.6)',
      backdropBorderRadius: '0',
      primaryColour: '#1C8F55',
      secondaryColour: '#096437',
      fullScreenBackdrop: true
    }),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-TX535MS',
    }),
    ImageCropperModule,
    LightboxModule,
    InfiniteScrollModule,
    RecaptchaModule,
    RecaptchaV3Module,
    ChartsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es-MX" },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeZlcsbAAAAAA8fzvOupriT3u_A0sOFkXZ_qRZ0'},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalComponent
  ],
})
export class AppModule { }
