import {Injectable} from '@angular/core';
import {HttpClientService} from '../http-client-service.service';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

export class SocialLoginResponse {
  isNewUser: boolean = false;
  image: string = null;
  birthdate: string = null;
  name: string = null;
  socialId: any = null;
  email: string = null;
  provider: string = null;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public API_URL: string;
  public API_URL_V2: string;

  constructor(public httpService: HttpClientService, private http: HttpClient) {
    this.API_URL = environment.API_URL;
    this.API_URL_V2 = environment.API_URL_V2;
  }

  login(data) {
    return this.httpService.getQuery('post', 'user/login', data).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
  validateEmail(data){
    return this.httpService.getQuery('post', 'user/validateuserdata', data).pipe(map((resp: any) => {
          return resp;
        }));
  }
  sendVerificationCode(data){
    return this.httpService.getQuery('post', 'user/sendcodevalidation', data).pipe(map((resp: any) => {
      return resp;
    }));
  }

  deletePersonalMail(data){
    return this.httpService.getQuery('put', 'user/deletepersonalemail', data).pipe(map((resp: any) => {return resp;
    }));
  }

  validateVerificationCodeInProfile(data){
       return this.httpService.getQuery('post', 'user/codeValidationPerfil', data).pipe(map((resp: any) => {
          return resp;
        }));
  }

  validateVerificationCode(data){
      return this.httpService.getQuery('post', 'user/codeValidation', data).pipe(map((resp: any) => {
          return resp;
        }));
  }

  resendVerificationCode(data){
      return this.httpService.getQuery('post', 'user/resendcode', data).pipe(map((resp: any) => {
          return resp;
        }));
  }

  validateTokenRecaptcha(data) {
    return this.httpService.getQuery('post', 'recaptcha', data).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  logout() {
    return this.httpService.getQuery('get', 'user/logout', null).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  resetPassword(data) {
    return this.httpService.getQuery('put', 'user/passwordReset', data).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  me() {
    return this.httpService.getQuery('get', 'user/me', null).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  changeProfilePicture(data) {
    return this.httpService
      .getQuery('post', 'user/photoProfileChange', data)
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  forgotPassword(data) {
    return this.httpService.getQuery('post', 'user/passwordRecover', data).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  getFrames() {
    return this.httpService.getQuery('get', 'catalogs/frame', null).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  setSelectedFrame(data) {
    return this.httpService.getQuery('put', 'frame/active', data).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  // Frame service version 2

  getFramesInitialPage() {
    return this.httpService.getQueryV2('get', 'catalogs/frame', null).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  getFramesPage(page) {
    const url = `${this.API_URL_V2}${'catalogs/frame'}`;
    let params = new HttpParams().set('page', page);
    return this.http.get(url, {
      params: params,
    });
  }

  getCompaniesList(view?: string) {
    const query = view ? `companies/${view}` : `companies`;
    return this.httpService.getQueryV2('get', query, null).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
  //Reset automated provisional password
  passwordResetProvisional(token, body) {
    const headers = new HttpHeaders({ Authorization: token });
    return this.http
      .put(`${this.API_URL}user/passwordResetProvisional`, body, {
        headers: headers,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
