import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-modal',
    template: `
        <div #pruebaModal>
            <div class="modal-body">
                <div class="modal-wrapper d-flex flex-column justify-content-center align-items-center">
                    <i class="fas fa-exclamation-circle mx-auto fa-2x mb-2" [hidden]="!error"></i>
                    <img [attr.src]="image" class="mx-auto"/>
                    <p class="modal-title my-1">{{title}}</p>
                    <p class="modal-text text-center">{{prompt}}</p>
                    <button class="btn ggi-button-red my-2" (click)="activeModal.close(true)">{{buttonContent}}</button>
                </div>
            </div>
        </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
    title: string;
    prompt: string;
    btnError: string;
    buttonContent: string;
    image: string;
    error:boolean;

    constructor(public activeModal: NgbActiveModal, public commonModule: CommonModule) {
    }
}
