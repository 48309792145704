<div class="container-fluid mobile-wrapper">
    <div class="row">
        <div class="companies-wrapper col-12 d-flex align-items-center pt-3">
            <img src="assets/images/logo-ggi@3x.png" alt="" class="company-logo logo-ggi mx-1 mx-sm-3">
            <img src="assets/images/logo-gg@3x.png" alt="" class="company-logo logo-gg mx-1 mx-sm-3">
            <img src="assets/images/logo-set@3x.png" alt="" class="company-logo logo-set mx-1 mx-sm-3">
            <!-- <img src="assets/images/logo-ths@3x.png" alt="" class="company-logo logo-ths mx-1 mx-sm-3"> -->
        </div>
        <div class="col-12 text-center mt-3">
            <h1 class="mobile-title">Bienvenido</h1>
            <p class="mobile-content">Para brindarte la mejor experiencia en dispositivos móviles <b>SOY GIGANTE</b> solo se puede usar mediante la aplicación.</p>
            <p class="mobile-content">Lamentamos los inconvenientes.</p>
            <div class="my-4">
                <a href="https://apps.apple.com/gt/app/soy-gigante/id1485945892" target="_blank" rel="noopener noreferrer" width="25%">
                    <img src="assets/images/mobile/icn-app-store.png" alt="" class="button-store mx-2">
                </a>
                <a href="https://play.google.com/store/apps/details?id=mx.iterando.gigante.employeeapp&hl=en_US" target="_blank" rel="noopener noreferrer" width="25%">
                    <img src="assets/images/mobile/icn-google-play.png" alt="" class="button-store mx-2" >
                </a>
                

            </div>
            <div class="w-100">
                <img src="assets/images/mobile/img-mobile.png" alt="" class="mobile-image">
            </div>

            <p class="mobile-content mt-3 mb-1 mt-sm-5 d-block d-sm-none">Si tienes problemas con la aplicación <br>por favor envía un correo a <br> <b>ayudaapp@gigante.com.mx</b></p>
            <p class="mobile-content mt-3 mb-1 mt-sm-5 d-none d-sm-block">Si tienes problemas con la aplicación por favor envía un correo a <br> <b>ayudaapp@gigante.com.mx</b></p>
        </div>
    </div>
</div> 